<template>
    <div class="sm:flex sm:items-center min-h-[46px]" :class="$route.name != 'Positions' && $route.name != 'Orders' && $route.name != 'util' ? 'secondary-violet-bg rounded-t' : ' '">
        <div class="flex flex-wrap justify-between items-center py-1 px-3 mx-auto w-full gap-2">
                <div class="flex items-center">
                    <div class="flex justify-between items-center">
                        <div class="primary-color text-sm font-semibold truncate flex items-center" :id="`page_head_${name}`"> {{ name }}</div>
                        <spinner v-if="getLoader && ($route.path == '/holdings')" />
                    </div>
                </div>
                <div class="flex items-center h-[30px]">
                    <div class="relative w-full" v-if="isSearch && data.length != 0 && !getIsMobileView">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-[#56585A] dark:text-[#94A3B8]">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd">
                                </path>
                            </svg>
                        </div>
                        <input maxlength="30" type="text" placeholder="Search" class="h-[26px] border border-gray-100 primary-color text-xs rounded  block w-full !pl-8 p-1 dark:bg-[#2f2f33] dark:border-[#232325] dark:placeholder-gray-400 dark:text-white focus:max-w-[170px] max-w-[100px] transition-all duration-300" v-model="searchTerm" :id="`${name?.split(' ')[0]}_search`" autocomplete="off">
                    </div>

                    <button :id="`create_${name?.split(' ')[0]}`" @click="createAlertOrBasket()" v-if="isCreate" class="border-[#753ED7] violet-bg text-white px-3 ml-2 flex justify-center items-center
                        font-medium text-xs rounded hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none focus:ring-0 active:bg-indigo-800 h-[26px] w-18 dark:border-gray-500">
                        Create
                    </button>
                    <button v-if="$route.path == '/holdings' && !getPoaClient && getHoldingsData?.length" :id="'holding_verify_sell'" @click="openEdisDialog('Auth')" type="button" class="ml-3 px-2 text-blue-600 hover:text-blue-700 font-semibold text-xs rounded  focus:outline-none hover:ring-0  transition duration-150 ease-in-out flex self-center content-center align-middle justify-center center item-center item-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 mr-1 text-blue-600 hover:text-blue-700">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <span class="self-center text-[10px] whitespace-nowrap">Verify to Sell</span>
                    </button>

                    <a v-if="data.length != 0" :id="name == 'Trade Book' ? 'downloadcsv1' : 'downloadcsv'">
                        <button :id="`${name?.split(' ')[0]}_download_btn`" type="button" class="px-2 text-blue-600 hover:text-blue-700 font-semibold text-xs rounded  focus:outline-none hover:ring-0 transition duration-150 ease-in-out flex self-center content-center align-middle justify-center center item-center item-middle" v-if="isDownload" @click="$common.checkDownload('file', name, data)">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                                class="w-3 mr-1 text-blue-600" role="img" width="20" height="20" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.36959 11.3215V3.33333C9.36959 2.8731 9.74268 2.5 10.2029 2.5C10.6632 2.5 11.0363 2.8731 11.0363 3.33333V11.3215L13.1492 9.20858C13.4746 8.88314 14.0022 8.88314 14.3277 9.20858C14.6531 9.53401 14.6531 10.0617 14.3277 10.3871L10.7922 13.9226C10.4667 14.248 9.93911 14.248 9.61367 13.9226L6.07809 10.3871C5.75265 10.0617 5.75264 9.53403 6.07807 9.20858C6.40351 8.88314 6.93115 8.88314 7.25659 9.20857L9.36959 11.3215ZM1.6665 11.6667C1.6665 11.2064 2.0396 10.8333 2.49984 10.8333C2.96007 10.8333 3.33317 11.2064 3.33317 11.6667C3.33317 12.4797 3.33359 14.4893 3.334 15C3.334 15.4596 3.70774 15.8333 4.16734 15.8333H15.834C16.2936 15.8333 16.6673 15.4596 16.6673 15V11.6667C16.6673 11.2064 17.0404 10.8333 17.5007 10.8333C17.9609 10.8333 18.334 11.2064 18.334 11.6667V15C18.334 16.3801 17.2141 17.5 15.834 17.5H4.16734C2.78727 17.5 1.66734 16.3801 1.66734 15.0007C1.66693 14.4901 1.6665 12.4802 1.6665 11.6667Z"
                                class=" fill-blue-600 hover:text-blue-700" />
                            </svg>
                            <span class="self-center text-[10px]">Download</span>
                        </button>
                    </a>
                </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            basketName: ''
        }
    },
    props: {
        name: { type: String },
        data: { type: Array },
        isSearch: { type: Boolean, default: false },
        isHistory: { type: Boolean, default: false },
        isDownload: { type: Boolean, default: false },
        isExit: { type: Boolean, default: false },
        isAnalytic: { type: Boolean, default: false },
        isAuthorisation: { type: Boolean, default: true },
        isRevoke: { type: Boolean, default: false },
        vmodel: { type: String },
        isCreate: { type: Boolean, default: false },
        isBasket: { type: Boolean, default: false }
    },
    computed: {
        ...mapGetters(['getSearch','getLoader','getIsMobileView']),
        ...mapGetters('holdings', ['getIsAuthorizedAll','getHoldingsData', 'getAuthorizeList', 'getRevocationList','getPoaClient']),

        searchTerm: {
            get() {
                return this.getSearch
            },
            set(val) {
                this.$store.commit('setSearch', val)
            }
        }
    },

    watch: {},
    methods: {
        createAlertOrBasket() {
            if (this.$store.state.activePageTab == '4') {
                this.$store.commit('alert/setCurrentAlertData', '')
                this.$store.commit('alert/setIsAlertDialog', { boolean: true, type: 'Create' })
            } else if(this.$store.state.activePageTab == '3') {
                this.$store.commit('setCreateBaskettDialog', true)
            }
        },
        openEdisDialog(val) {
            // this.$store.commit('holdings/setIsEdisAuth', val)
            // this.$store.commit('holdings/setIsEdisDialog', true)
            this.$store.dispatch('holdings/authEdis')
        }
    },
    mounted() {},
}
</script>