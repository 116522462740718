<template>
    <ol class="flex flex-col border-t border-[#ededed] dark:border-[#252323]">
        <li v-for="(item, idx) in listData" :key="idx"
            class="p-2 w-full flex flex-col gap-2 border-b border-[#ededed] dark:border-[#252323]"
            @click="toggleBottomSheet(item, idx)">
            <section class="flex justify-between">
                <span class="text-sm primary-color">{{ item.formattedInsName }}</span>
                <span class="text-right text-sm dark:text-[#94A3B8]">
                    <span>
                        {{ item.exchange == "CDS" || item.exchange == "BCD"
                            ? parseFloat(item?.fillprc).toFixed(4)
                            : parseFloat(item?.fillprc).toFixed(2)
                        }}
                    </span>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="primary-color">
                    <button :class="$common.getTypeTdClass(item.transType)" class="px-3 rounded min-h-[24px]">{{
                        item.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                    <span class="pl-1">{{ ` ${item["fillShares"] ? getFillShares(item) : '0'} / ${getLot(item)}
                        `}}</span>
                </span>
                <span>
                    <button
                        class="px-3 text-[10px] rounded cursor-default green-btn-c tracking-[0.4px] min-h-[24px]">COMPLETE</button>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="flex gap-1 secondary-color">
                    <span>{{ item?.exchange }}</span>
                    <span>{{ item?.priceType == 'L' ? 'LIMIT' : item?.priceType }}</span>
                    <span>{{ $common.getPcode(item) }}</span>
                </span>
                <span class="secondary-color">
                    {{ item.orderTime?.split(' ')[0] }}
                </span>
            </section>
        </li>
    </ol>
    <BottomSheet page="tradebook" v-if="isOpenBottomSheet" :isOpen="isOpenBottomSheet" @close="closeBottomSheet"
        :options="sheetOptions" :sheetData="sheetData" @call_action="callAction" />
</template>

<script setup lang="ts">
import { PropType, ref, defineAsyncComponent } from "vue"

const BottomSheet = defineAsyncComponent(()=> import("../../component/bottom-sheet.vue"))
defineProps({
    listData: {
        type: Array as PropType<any[]>,
        required: true
    }
})
const isOpenBottomSheet = ref(false)
const sheetData = ref()
const toggleBottomSheet = async (data: any, index: Number) => {
    await setBottomSheetOptions()
    sheetData.value = { data, index }
    isOpenBottomSheet.value = !isOpenBottomSheet.value
}

const sheetOptions = ref()
const setBottomSheetOptions = async () => {
    sheetOptions.value = ['Repeat','Info', 'Chart']
}
const closeBottomSheet = () => {
    isOpenBottomSheet.value = false
}

const emits = defineEmits(['call_action'])

const callAction = (data:any) => {
    emits('call_action', data)
}

const getFillShares = (item: any) => {
    let fillShares = item.exchange == 'MCX' ? item.fillShares / item.lotSize : item.fillShares
    return fillShares
}
const getLot = (item: any) => {
    let qty = item.exchange == 'MCX' ? item.qty / item.lotSize : item.qty
    return qty
}
</script>