<template>
    <box :class="getIsTickerTape == '1' ? 'calc-height-2' : 'calc-height-1'">
        <div>
            <Tabs page="orders" class="m-0" :isBgBlue="true" :data="getOrderBookTabs" @activeTab="activeTabObj" />
        </div>
        <div v-if="!getLoader" class="">
            <div v-for="(table, index) in tabTable" :key="index">
                <div v-if="activetab == index">
                    <order_table v-if="activetab == 0" :name="table.tableName" :full="table" :isOpen="true" />
                    <order_table v-if="activetab == 1" :name="table.tableName" :full="table" :isOpen="false" />
                </div>
            </div>
            <trade_book v-if="activetab == 2" />
            <basket v-if="activetab == 3" />
            <alert v-if="activetab == 4" />
            <gtt v-if="activetab == 5" />
            <sip v-if="activetab == 6" />
        </div>
    </box>
</template>
<script >
import order_table from '../order/order-table.vue'
import trade_book from '../order/trade-book.vue'
import cdialog from '../order/cancel-model.vue'
import basket from './basket/basket.vue'
import table_head_section from '../../component/table-head-section.vue'
import alert from '../order/alert/alert.vue'
import gtt from './GTT/gtt.vue'
import sip from './SIP/sip.vue'

import { mapGetters } from 'vuex'
import CancelModel from '../order/cancel-model.vue';
const downIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                </svg>`
export default {
    data() {
        return {
            downIcon,
            activetab: null,
            tabTable: [{ tableName: "Pending Orders", analytic: false, history: false, download: true, search: true, tableheader: true },
                        { tableName: "Executed Orders", analytic: false, history: false, download: true, search: true, tableheader: true}],
            isDownIcon: true,
        }
    },
    components: {
        table_head_section, CancelModel, order_table, trade_book, cdialog, basket, alert, gtt, sip
    },
    computed: {
        ...mapGetters('order', ['getOrderBookTabs','getOpenOrders']),
        ...mapGetters(['getExitPositionDialog', 'getExitPositions']),
        ...mapGetters(['getLoader']),
        ...mapGetters('positions', ['getPositionsData']),
        ...mapGetters('login', ['userRole']),
        ...mapGetters('pref', ['getIsTickerTape']),
    },
    methods: {
        async activeTabObj(val, from) {
            val = Number(val)
            // localStorage.setItem('orderTab', val)
            // this.$router.push({ path: this.$route.name }).catch(() => { })
            this.$store.commit("setQuries", { data: { tab: val }, action: "change" });
            this.activetab = val
            this.$store.commit('setActivePageTab', this.activetab)
            this.getOrderBookTabs.forEach((el, id) => {
                if (id == val) {
                    el.active = true
                } else {
                    el.active = false
                }
            });
            this.$store.commit('order/setOrdersTabs', this.getOrderBookTabs)
            if (!from) {
                if (val == 0 || val == 1) {
                    await this.$store.dispatch('order/getUserOrders')
                    window.orderWatch = {
                        callback: this.orderListen,
                    }
                } else if (val == 2) {
                    this.$store.dispatch('order/getTradeBook')
                } else if (val == 3) {
                    this.$store.dispatch('basket/getBasketList')
                } else if (val == 4) {
                    this.$store.dispatch('alert/getAlerts')
                } else if (val == 5) {
                    this.$store.dispatch('gtt/getGTTOrders')
                } else if (val == 6) {
                    this.$store.dispatch('sip/getSIPOrders')
                }
            } else if (from == 'initial') {
                this.$store.dispatch('order/getUserOrders')
                this.$store.dispatch('order/getTradeBook')
                this.$store.dispatch('basket/getBasketList')
                this.$store.dispatch('alert/getAlerts')
                this.$store.dispatch('gtt/getGTTOrders')
                // this.$store.dispatch('sip/getSIPOrders')
                
            }
            this.$common.getDocumentTitle(this.$route)
            this.$store.commit('setSearch', '')
        },

        orderListen(wsd, token) {
            if(this.getOpenOrders.length != 0) {
        this.getOpenOrders.forEach(el => {
            if(el.wsToken == token && wsd[token]?.lp) {
                el.ltp = wsd[token].lp
                el.ltp = el?.exch == 'CDS' || el?.exch == 'BCD'
                ? parseFloat(el.ltp).toFixed(4) : parseFloat(el.ltp).toFixed(2)
            }
        });
    }
        }
    },
    created() {
        this.$store.commit("order/setOrdersTabs", [
            { name: 'Pending', id: 0, active: true, count: '0' },
            { name: 'Executed', id: 1, active: false, count: '0' },
            { name: 'Tradebook', id: 2, active: false, count: '0' },
            { name: 'Basket', id: 3, active: false, count: '0' },
            { name: 'Alerts', id: 4, active: false, count: '0' },
            { name: 'GTT', id: 5, active: false, count: '0' },
            // { name: 'SIP', id: 6, active: false, count: '0' }
        ])
        this.$store.commit(
        "setActivePageTab",
        this.$store.state.queries["order"] ? this.$store.state.queries["order"].query.tab : 0
      );
      
        this.activetab = this.$store.state.activePageTab

        this.activeTabObj(this.activetab, 'initial')
        this.$store.commit('setSearch', '')
    },

    unmounted(){
        delete window.orderWatch
    }
}
</script>