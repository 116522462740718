<template>
    <div>
        <div v-if="sipList.length != 0 && !getLoader">
        <table_head_section class="mt-[2px]" :isSearch="true" :isCreate="true" :data="sipList" :name="`SIP ${sipList.length ? `(${sipList.length})` : ''}`">
        </table_head_section>
        <div  class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative ring-black ring-opacity-5 overflow-y-auto" >
                        <table class="min-w-full table-fixed divide-y divide-gray-300 dark:divide-[#232325] rounded-b border-t border-[#ededed] dark:border-[#232325] table-common">
                            <thead class="border-b-1 dark:border-[#9c9cb1]">
                                <tr>
                                    <th id="sip_th_created_on" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">SIP Name</th>
                                    <th id="sip_th_name" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Frequency</th>
                                    <th id="sip_th_symbol" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Registered On</th>
                                    <th id="sip_th_sip_type" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Pending Period</th>
                                    <th id="sip_th_status" scope="col" class="p-3 text-center text-xs font-semibold secondary-color">Status</th>
                                </tr> 
                            </thead>
                            <tbody >
                                <tr v-for="(sip, idx) in getSearch == '' ? sipList : filterData" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1;" :class="sipList.length != 1 && filterData.length != 1 ? 'last:border-0' :'' "  class="border-b border-[#ededed] dark:border-[#232325] h-[56px]">
                                    <td :id="`sip_td_created_on_${idx}`" class="p-3 text-left text-sm relative primary-color">{{ sip?.sipName }}</td>
                                    <td :id="`sip_td_name_${idx}`" :name="sip?.sipName" class="p-3 text-left text-sm relative primary-color">{{ sip?.frequency == 0 ? 'Daily' : ''}}</td>
                                    <td :id="`sip_td_symbol_${idx}`" class="p-3 text-left text-sm primary-color">
                                        <span class="mb-2">{{ sip.startDate }}</span>
                                    </td>
                                    <td class="text-left p-3 text-sm relative primary-color">
                                        {{ sip.endPeriod }}
                                    </td>
                                    <td class="text-center p-3 text-sm primary-color">
                                        <button :id="`sip_td_status_${idx}`" :class="sip?.internal.active ? 'green-btn-c' : 'gray-btn-c'" class="px-3 text-[10px] rounded min-w-[100px] cursor-default tracking-[0.4px] min-h-[24px]">{{ sip?.internal.active ? 'Active' : 'Open' }}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata type="SIP Orders"/>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import table_head_section from '../../../component/table-head-section.vue'
import nodata from '../../no-data.vue'
export default{
    data() {
        return {
            currentIndex: -1,
        }
    },
    computed: {
        ...mapState('sip', ['sipList']),
        ...mapGetters(['getSearch','getLoader']),
        filterData() {
            return this.sipList.filter((post) => {
                return (
                    post.sipName?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },
    },
    components: { table_head_section, nodata },
    methods: {
        
    }
}
</script>

<style>
.sip-btn {
    @apply px-3 py-2 text-[12px] rounded cursor-default min-w-[100px] font-medium
}
.sip-btn.enabled {
    @apply bg-lime-50 text-lime-500
}
.sip-btn.disabled {
    @apply bg-orange-50 text-orange-500
}
</style>